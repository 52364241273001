import './App.css';

function App() {
  return (
    <div className="App">
      <body>
        Weston is #1!
      </body>
    </div>
  );
}

export default App;
